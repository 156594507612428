import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import cycling from '../../Assets/Hobby/cycling.JPG';
import cooking from '../../Assets/Hobby/cooking.jpeg';
import hiking from '../../Assets/Hobby/hiking.JPG';
import plane from '../../Assets/Hobby/Plane.gif';

function Projects() {
  return (
    <Container fluid className="hobby-section" style={{padding: '0px'}}>
      <Container fluid className="hobby-about-section" id="about" style={{padding: '0px'}}>
        <h1 className="project-heading">
          My <strong className="purple">Hobbies </strong>
        </h1>
        <p style={{ color: "#0c131e", paddingBottom: '40px'}}>
          Here are a few things that I love to do when I am not busy with a project (and yes I cannot seat in one place easily).
        </p>
        <Container className="container-about">
          <Row
            style={{textDecoration: 'none'}}
          >
            <Col md={8} className="home-about-description" style={{padding: '0px', background: '#0F0D04'}}>
            <div><img className="section-img" src={cooking} alt="MomA Art"></img></div>
            <div className="tile-description" style={{paddingTop: '6%',  color: '#ffffff'}}>
              Cooking
              <p style={{fontWeight: '200', paddingTop: '2%', color: '#ffffff'}}>is not just making food, it's about creating something delicious, nourishing, and filled with love.</p>
            </div>
            </Col> 
          </Row>
          <Row 
            style={{textDecoration: 'none'}}
          >
            <Col md={8} className="home-about-description-right" style={{padding: '0px', background: '#DFAE1C'}}>
                <div><img className="section-img-right" style={{width: "400px", height: "240"}} controls autoplay loop src={plane} alt="MomA Art"></img></div>
                <div className="tile-description" style={{paddingTop: '6%'}}>
                  Travelling 
                <p style={{fontWeight: '200', paddingTop: '2%'}}>The ultimate way to broaden your horizons, create unforgettable memories, and discover new cultures and perspectives.</p>
                </div>
            </Col> 
          </Row>
          <Row
            style={{textDecoration: 'none'}}
          >
            <Col md={8} className="home-about-description" style={{padding: '0px', background: '#0F0D04'}}>
              <div><img className="section-img" src={hiking} alt="MomA Art"></img></div>
              <div className="tile-description" style={{paddingTop: '6%', color: '#ffffff'}}>
                Hiking
              <p style={{fontWeight: '200', paddingTop: '2%'}}>The perfect way to escape the hustle and bustle of everyday life and connect with nature.</p>  
              </div>
            </Col> 
          </Row>
          <Row 
            style={{textDecoration: 'none'}}
          >
            <Col md={8} className="home-about-description-right" style={{padding: '0px', background: '#DFAE1C'}}>
                <div><img className="section-img-right" src={cycling} alt="MomA Art"></img></div>
                <div className="tile-description" style={{paddingTop: '6%'}}>
                  Cycling 
                <p style={{fontWeight: '200', paddingTop: '2%'}}> is all about the exhilarating feeling of freedom and adrenaline rush on the open road.</p>
                </div>
            </Col> 
          </Row>
        </Container>  
      </Container>
    </Container>
  );
}

export default Projects;
